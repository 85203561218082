import React from 'react'
import { SearchData } from '../SearchPageSample/interfaces/SearchData'
import SearchPageSample from '../SearchPageSample/SearchPageSample'

const Artisant = (): JSX.Element => {
    let data: SearchData = {
        id: "artisant",
        title: "Construction industry craftsmen",

        buttonAdd: "Offer your services",
        
        search: {
            types: ["Construction", "Handworks"],
            placeholder: "Search for an ad",
            categories: [""]
        },
        
        annoncesTitle: "All the artisans",
        annonces: [
            {
                type: "All",

                link: "/artisant/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Title of the craftsman",
                pro: false,

                prix: undefined,
                refPrix: undefined,

                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
                location: "Paris 75",

                note: 4,
                avis: 16
            },
            {
                type: "Handwork",

                link: "/artisant/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Title of the craftsman",
                pro: false,

                prix: undefined,
                refPrix: undefined,

                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
                location: "Paris 75",

                note: 4,
                avis: 16
            },
            {
                type: "Construction",

                link: "/artisant/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Title of the craftsman",
                pro: true,

                prix: undefined,
                refPrix: undefined,

                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
                location: "Paris 75",

                note: 4,
                avis: 16
            }
        ],
    }
    return (
        <SearchPageSample data={ data } />
    )
}

export default Artisant