import React from 'react'
import { SearchData } from '../SearchPageSample/interfaces/SearchData'
import SearchPageSample from '../SearchPageSample/SearchPageSample'
import "./annonce_emploi.css"

const Emploi = (): JSX.Element => {
    let data: SearchData = {
        id: "emploi",
        title: "Construction job offers",

        buttonAdd: "Post a job offer",
        
        search: {
            types: ["Long Term", "Short Term", "Extra"],
            placeholder: "Search for an offer",
            categories: [""]
        },
        
        annoncesTitle: "All the job offers",
        annonces: [
            {
                type: "Long Term",

                link: "/emploi/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Job title'",
                pro: false,

                prix: 2000,
                refPrix: "/ month",

                description: "Job description, Lorem ipsum dolor sit amet, consectetur...",
                location: "Paris 15e 75015",

                note: undefined,
                avis: undefined
            },
            {
                type: "Short Term",

                link: "/emploi/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Job title'",
                pro: false,

                prix: 20,
                refPrix: "/ hour",

                description: "Job description, Lorem ipsum dolor sit amet, consectetur...",
                location: "Créteil 94000",

                note: undefined,
                avis: undefined
            },
            {
                type: "Extra",

                link: "/emploi/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Job title'",
                pro: false,

                prix: 100,
                refPrix: "/ jour",

                description: "Job description, Lorem ipsum dolor sit amet, consectetur...",
                location: "Bobigny 93000",

                note: undefined,
                avis: undefined
            }
        ],
    }
    return (
        <SearchPageSample data={ data } />
    )
}

export default Emploi