import React from 'react'
import { SearchData } from '../SearchPageSample/interfaces/SearchData'
import SearchPageSample from '../SearchPageSample/SearchPageSample'
import "../SearchPageSample/search_page.css"

const Materiel = (): JSX.Element => {
    let data: SearchData = {
        id: "materiel",
        title: "Construction equipment ads",

        buttonAdd: "Post an ad",
        
        search: {
            types: ["Rent", "Buy"],
            placeholder: "Search an ad",
            categories: [""]
        },
        
        annoncesTitle: "All the ads",
        annonces: [
            {
                type: "Rent",

                link: "/materiel/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Ad title",
                pro: false,

                prix: 100,
                refPrix: "/ day",

                description: "Ad description, Lorem ipsum dolor sit amet, consectetur...",
                location: "Paris 15e 75015",

                note: undefined,
                avis: undefined
            },
            {
                type: "Rent",

                link: "/materiel/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Ad title",
                pro: true,

                prix: 100,
                refPrix: "/ hour",

                description: "Ad description, Lorem ipsum dolor sit amet, consectetur...",
                location: "Créteil 94000",

                note: undefined,
                avis: undefined
            },
            {
                type: "Buy",

                link: "/materiel/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Ad title",
                pro: false,

                prix: 50,
                refPrix: " ",

                description: "Ad description, Lorem ipsum dolor sit amet, consectetur...",
                location: "Bobigny 93000",

                note: undefined,
                avis: undefined
            },
            {
                type: "Buy",

                link: "/materiel/annonce",
                
                img: "/search/icon_ui_picture.png",
                title: "Ad title",
                pro: false,

                prix: 1000,
                refPrix: " ",

                description: "Ad description, Lorem ipsum dolor sit amet, consectetur...",
                location: "Versailles 78000",

                note: undefined,
                avis: undefined
            }
        ],
    }
    return (
        <SearchPageSample data={ data } />
    )
}

export default Materiel
