import React, { useEffect, useState } from 'react'
import { header_dark } from '../utils/header_dark'
import "./inscription.css"
import Mail from './Particulier/Mail'
import Entreprise from './Professionnel/Entreprise'

const Inscription = (): JSX.Element => {
    useEffect(() =>  {
        header_dark()
    })

    let [ page, setPage ] = useState<JSX.Element>(
        <div className="inscription">
            <h1>Creating an account</h1>
            <p>In order to benefit from all the features of the site, you must create an account.</p>
            <div id="type">
                <span>Account type :</span>
                <button onClick={() => setPage(<Mail />)}>Casual</button>
                <button onClick={() => setPage(<Entreprise />)}>Professional</button>
            </div>
        </div>
    )

    return page
}

export default Inscription