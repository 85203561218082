import React from 'react'
import "./footer.css"

const Footer = (): JSX.Element => {
    return (
        <div id="footer">

        </div>
    )
}

export default Footer